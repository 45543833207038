var ismobile = '23';
var $window;
var $header;
var $teaser;
var $navbar;
var fadeableContent;
var fadeableFooter;
var $teasertext;
var $teasertextContainer;
var menueMaxScrollHoehe = 110;
var teaserSliderScrollFaktor = 2.5;

var $embedResponsive;

var $logo;
var $realLogo;

var $mainMenueLi;

var md;
var $banner;

var $fbIcon;

var intersectionObserver;

$(document).ready(function () {
    md = new MobileDetect(window.navigator.userAgent);
    $window = $(window);
    $header = $('header');
    $teaser = $('.main__teaser__tease');
    $logo = $header.find('.logo-left');
    $realLogo = $logo.find('img');
    $embedResponsive = $teaser.find('.embed-responsive');
    $banner = $('.banner');
    $fbIcon = $header.find('.facebook-link');
    $teasertextContainer = $('.teaser-text--container');

    $navbar = $header.find('.navbar');
    $teasertext = $teaser.find('.teaser-text');

    onepager.variables.callback = function() {
        initFlexsliderTeaser();
        $window.trigger('scroll');
        initScrollTop();
    };

    windowResize();
    $window.on('resize', function () {
        windowResize();
        if(!ismobile) {
            // initTeaserSize();
            // setLogoTop();
        }
        // setContentStartOffset();
    });
    $window.on('scroll', function (e) {
        if (!ismobile) animateMenue(e);
        // fadeContent();
    });

    // if(!intersectionObserver) {
    //     $window.on('scroll', function (e) {
    //         fadeContent();
    //     });
    // }

    initFadeTeaser();
    // initTeaserSize();
    // initFadeContent();
    $window.trigger('scroll');
    // $window.trigger('resize');

    initScrollTop();
    
    if(ismobile) {
        initMobileFunctions();
    }

    initFlexsliderTeaser();

    countdown();
});

function countdown() {
    var counter = $('.countdown-date');
    var datum = counter.data('date');
    var tage = $(".countdown-date span[data='days']");
    var stunden = $(".countdown-date span[data='hours']");
    var minuten = $(".countdown-date span[data='minutes']");
    var sekunden = $(".countdown-date span[data='seconds']");
    var x = setInterval(function() {
        var countdownDate = new Date(datum).getTime();
        var aktuell = new Date().getTime();

        var zeitraum = countdownDate - aktuell;

        var days = Math.floor(zeitraum / (1000 * 60 * 60 * 24));
        var hours = Math.floor((zeitraum % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((zeitraum % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((zeitraum % (1000 * 60)) / 1000);

        tage.html(days);
        stunden.html(hours);
        minuten.html(minutes);
        sekunden.html(seconds);
    }, 1000);

}

function lazyLoad() {
    // initFadeContent();
    fitNavspaltenHeaders();
    $(window).trigger('scroll');
}

function initFadeContent() {
    fadeableContent = $('article').find('.container-content').find('>div,>p,>h1,>h2,>strong').not('.no-fade');
    fadeableFooter = $('.footer').find('.footerinhalt');

    if(!intersectionObserver) {
        $.each(fadeableContent, function (i, v) {
            $(v).data().top = $(v).offset().top;
        });
        fadeableFooter.data().top = fadeableFooter.offset().top;
    } else {
        $.each(fadeableContent, function(i, v) {
            intersectionObserver.observe(v);
        });
        intersectionObserver.observe(fadeableFooter.get(0));
    }
}

function fadeContent() {
    var offset = $window.scrollTop() + $window.height();
    $.each(fadeableContent, function (i, v) {
        if ($(v).data().top < offset) {
            $(v).addClass('fadeContentIn');
        }
    });
    if (fadeableFooter.data().top < offset) {
        fadeableFooter.addClass('fadeContentIn');
    }
}

function windowResize() {
    if (md.mobile()) {
        if (!ismobile || ismobile === '23') {
            ismobile = true;
            $('.backgroundvideo').html('');
            $('.header').css('transform', '');
        }
    } else {
        if (ismobile || ismobile === '23') {
            ismobile = false;
        }
    }
}

function animateMenue(e) {
    if (ismobile) return;
    var scrollhoehe = e.currentTarget.scrollY;

    var headerOffset = scrollhoehe / 4;

    var m = scrollhoehe / 6;
    var bannerHeight = scrollhoehe / 3;
    if(bannerHeight > 500) bannerHeight = 500;
    // var scale = 1*(scrollhoehe/30);
    // if(scale < 1) scale = 1;
    // if(scale > 10) scale = 10;

    if(headerOffset > menueMaxScrollHoehe) headerOffset = menueMaxScrollHoehe;
    $header.css('transform','translateY(-'+headerOffset+'px)');


    var logoHeight = 130 - headerOffset;

    if(logoHeight < 50) logoHeight = 50;
    if(logoHeight > 130) logoHeight = 130;

    $logo.css('height', logoHeight + 'px');
    $realLogo.css('height', logoHeight + 'px');

    var fbIconTop = 15 + headerOffset;
    if(fbIconTop > 70) fbIconTop = 70;
    $fbIcon.css('top', fbIconTop + 'px');

    var navbarTranslateTop = scrollhoehe / 8;
    if(navbarTranslateTop > 23) navbarTranslateTop = 23;
    $navbar.css('transform', 'translateY(-50%) translateY(-'+navbarTranslateTop+'px)');

    if($teaser.hasClass('hideTeaser') === false) {
        m = m * teaserSliderScrollFaktor;
        $teaser.css('transform','translateY('+m+'px)');
        $teaser.find('.flex-control-nav').css('transform','translateY(-'+m+'px)');
        $teasertextContainer.css('transform', 'translateX(-50%) translateY(-'+m+'px)');
        // $banner.css('transform','scaleY('+scale+')');
        $banner.css('padding-top',(100+bannerHeight)+'px');
    }
}

function initKontaktformular() {
    var $frmContact = $('#frmContact');
    var $submitBtn = $('#submitBtn');
    $frmContact.submit(function () {
        var l = Ladda.create(document.querySelector('#submitBtn'));
        l.start();
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: $frmContact.serialize(),
        }).done().then(function (msg) {
            l.stop();
            msg = JSON.parse(msg);
            var tmp = $submitBtn.parent().html();
            $submitBtn.parent().html('<div class="alert alert-' + msg.status + ' text-left fade in">' + msg.text + '</div>');
            if (msg.status === 'success')
                $frmContact[0].reset();
            setTimeout(function () {
                $('.alert').parent().fadeOut(300, function () {
                    $('.alert').parent().html(tmp);
                    $submitBtn.parent().fadeIn(300);
                });
            }, 1500);
        });

        return false;
    });
}

function initMobileFunctions() {
    $('.mobilemenu__button').off('click').on('click', function () {
        $('.mobilemenu').addClass('open');
    });
    $('.mobilemenu__button-close, .mobilemenu').off('click').on('click', function () {
        $('.mobilemenu').removeClass('open');
        $('.mobilemenu__button-close').css('position', 'absolute');
    });
    $('.mobilemenu').on('transitionend', function (e) {
        if(parseInt(getComputedStyle(e.target).right) == 0) {
            $(e.target).find('.mobilemenu__button-close').css('position', 'fixed');
        } else {
            $('.mobilemenu').scrollTop(0);
        }
    });
}

function initFadeTeaser() {
    $teasertext.addClass('show');
    $teasertext.find('span').addClass('show');
}

function fitNavspaltenHeaders() {
    $('.navspalten--listing').each(function(i, row) {
        var maxHeight = 0;
        var $navspalten = $(row).find('.navspalten').find('.navspalten--headline');
        $navspalten.each(function(x, ele) {
            var height = parseInt($(ele).css('height'));
            if(maxHeight < height) maxHeight = height;
        });
        $navspalten.css('height',maxHeight+'px');
    });
}

function initTeaserSize() {
    var h2Offset = 130;
    var height = $window.height() - h2Offset;
    if($teaser.hasClass('main__teaser__tease')) {
        $teaser.css('height', height+'px');
    }
}

function initScrollTop() {
    $('.scrollTop').off('click').on('click', function() {
        $window.scrollTop(0);
    });
}

var disabledLink = function(e) {
    e.preventDefault();
    e.stopPropagation();
}